import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faDatabase, faPlay, faIndustry } from "@fortawesome/free-solid-svg-icons"
import Step from '../components/Molecules/Step/Step';

export default function RoomBooking({ data }) {
    debugger;
    const page = data.allContentfulZSolutions.edges[0].node

    const Bold = ({ children }) => <span className="bold3">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="h1-medium-rtxt h1-darkBlue">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="h1-medium2Medium h1-darkBlue">{children}</h4>
            ),
            [BLOCKS.HEADING_5]: (node, children) => (
                <h4 className="h5-medium-rtxt text-darkgray2">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        slAoRtxt,
        slAoRtxt2,
        slAoRtxt3,
        slAoRtxt4,
        slAoRtxt5,
        slAoRtxt6,
        slAoRtxt7,
        slAoRtxt8,
        slAoRtxt9,
        slAoRtxt10,
        slAoRtxt11,
        slAoRtxt12,
        slFHd,
        slFTxt,
        slFBtn,
        slHHd,
        slHHd2,
        slHTxt,
        slS1Hd,
        slS2Hd,
        slS3Hd,
        slS3Rtxt,
        slS4Rtxt,
        slS5Rtxt,
        slS6Rtxt,
        slS7Hd,
        slS7Rtxt,
        slS8Hd,
        slHImage,
    } = page

    //for responsiveness
    const [width, setWindowWidth] = useState(0)
    useEffect(() => {

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    }, [])
    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const responsive = {
        webContainer: width > 960
    }

    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <meta name="robots" content="noindex" />
            </head>

            {/*Header section*/}
            <section className="bg-white">
                <div className="head-container2">
                    <div className="row align-items-center pb-5">
                        <div className="col-lg-8">
                            <div className="bg-white">
                                <div className="row">
                                    <h1 className="align-top h1-small h1-purple">
                                        {slHHd2}
                                    </h1>
                                </div>
                                <div className="row pt-5 pr-4">
                                    <h1 className="h1-largeMedium2 pt-3 h1-darkBlue">
                                        {slHHd}
                                    </h1>
                                </div>
                                <div className="row pt-4 pr-4">
                                    <h2 className="h2-large text-darkgray">
                                        {slHTxt.slHTxt}
                                    </h2>
                                </div>
                                <div class="row pt-4">
                                    <div class="text-center">
                                        <Button
                                            className='btns'
                                            buttonStyle='btn--outlinePurple'
                                            buttonSize='btn--large'
                                            href={config.navigationURL_register}
                                        >
                                            <FormattedMessage id="start_now" />{" "}
                                            <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                        </Button>
                                    </div>
                                    <div class="text-center">
                                        <Button
                                            className='btns'
                                            buttonStyle='btn--primaryPurple'
                                            buttonSize='btn--large'
                                            href={config.navigationURL_contactSales}
                                        >
                                            <FormattedMessage id="contact_sales" />{" "}
                                            <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex__wrapper col-lg-4 bg-white pt-5">
                        </div>
                    </div>
                    {responsive.webContainer === true ? (

                        <div className="row text-white pt-5">
                            <div className="col-lg-4 pl-0 pr-5 pb-5">
                                <img
                                    src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                    style={{ width: "20%" }}
                                    alt="image"
                                />
                                <p className="p-regular4 text-darkgray pt-4">
                                    {documentToReactComponents(slAoRtxt.json, options)}
                                </p>
                            </div>
                            <div className="col-lg-4 pl-0 pr-5 pb-5">
                                <img
                                    src={require("../images/Icons/product-occupancy/warnings.svg")}
                                    style={{ width: "20%" }}
                                    alt="image"
                                />
                                <p className="p-regular4 text-darkgray pt-4">
                                    {documentToReactComponents(slAoRtxt2.json, options)}
                                </p>
                            </div>
                            <div className="col-lg-4 pl-0 pr-5 pb-5">
                                <img
                                    src={require("../images/Icons/product-occupancy/warnings.svg")}
                                    style={{ width: "20%" }}
                                    alt="image"
                                />
                                <p className="p-regular4 text-darkgray pt-4">
                                    {documentToReactComponents(slAoRtxt3.json, options)}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="row align-items-center pt-5">
                                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                    <img
                                        src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                        style={{ width: "100%" }}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                    <p className="p-regular4 text-darkgray pt-4">
                                        {documentToReactComponents(slAoRtxt.json, options)}
                                    </p>
                                </div>
                            </div>
                            <div className="row align-items-center pt-2">
                                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                    <img
                                        src={require("../images/Icons/product-occupancy/warnings.svg")}
                                        style={{ width: "100%" }}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                    <p className="p-regular4 text-darkgray pt-4">
                                        {documentToReactComponents(slAoRtxt2.json, options)}
                                    </p>
                                </div>
                            </div>
                            <div className="row align-items-center pt-2">
                                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                    <img
                                        src={require("../images/Icons/product-occupancy/warnings.svg")}
                                        style={{ width: "100%" }}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                    <p className="p-regular4 text-darkgray pt-4">
                                        {documentToReactComponents(slAoRtxt3.json, options)}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>

            {/*Section 1*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    {responsive.webContainer === true ? (
                        <div className="row">
                            <div className="col-lg-6 pb-5">
                                <div className="pr-5">
                                </div>
                            </div>
                            <div className="col-lg-6 pb-5">
                                <div className="row pr-5 pt-4">
                                    <h1 className="align-top h1-medium h1-darkBlue pb-3 pr-5">
                                        {slS1Hd}
                                    </h1>
                                    <div className="row align-items-center pt-5">
                                        <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                            <img
                                                src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                                style={{ width: "100%" }}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                            <p className="p-regular4 text-darkgray pt-4">
                                                {documentToReactComponents(slAoRtxt3.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-center pt-2">
                                        <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                            <img
                                                src={require("../images/Icons/product-occupancy/warnings.svg")}
                                                style={{ width: "100%" }}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                            <p className="p-regular4 text-darkgray pt-4">
                                                {documentToReactComponents(slAoRtxt4.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-center pt-2">
                                        <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                            <img
                                                src={require("../images/Icons/product-occupancy/warnings.svg")}
                                                style={{ width: "100%" }}
                                                alt="image"
                                            />
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                            <p className="p-regular4 text-darkgray pt-4">
                                                {documentToReactComponents(slAoRtxt5.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-lg-6 pb-5">
                                <div className="row pr-5 pt-4">
                                    <h1 className="align-top h1-medium h1-darkBlue pb-3 pr-5">
                                        {slS1Hd}
                                    </h1>
                                </div>
                                <div className="row align-items-center pt-5">
                                    <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                        <img
                                            src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                            style={{ width: "100%" }}
                                            alt="image"
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                        <p className="p-regular4 text-darkgray pt-4">
                                            {documentToReactComponents(slAoRtxt3.json, options)}
                                        </p>
                                    </div>
                                </div>
                                <div className="row align-items-center pt-2">
                                    <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                        <img
                                            src={require("../images/Icons/product-occupancy/warnings.svg")}
                                            style={{ width: "100%" }}
                                            alt="image"
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                        <p className="p-regular4 text-darkgray pt-4">
                                            {documentToReactComponents(slAoRtxt4.json, options)}
                                        </p>
                                    </div>
                                </div>
                                <div className="row align-items-center pt-2">
                                    <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                        <img
                                            src={require("../images/Icons/product-occupancy/warnings.svg")}
                                            style={{ width: "100%" }}
                                            alt="image"
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                        <p className="p-regular4 text-darkgray pt-4">
                                            {documentToReactComponents(slAoRtxt5.json, options)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pb-5">
                                <div className="pt-5 pr-5">
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            {/*Section 2*/}
            <section className="bg-white ">
                <div className="sectionFit-container bg-white ">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h1 className="align-top h1-medium h1-darkBlue pb-3 pr-5">
                                    {slS2Hd}
                                </h1>
                                <div className="row align-items-center pt-5">
                                    <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                        <img
                                            src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                            style={{ width: "100%" }}
                                            alt="image"
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                        <p className="p-regular4 text-darkgray pt-4">
                                            {documentToReactComponents(slAoRtxt7.json, options)}
                                        </p>
                                    </div>
                                </div>
                                <div className="row align-items-center pt-2">
                                    <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                        <img
                                            src={require("../images/Icons/product-occupancy/warnings.svg")}
                                            style={{ width: "100%" }}
                                            alt="image"
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                        <p className="p-regular4 text-darkgray pt-4">
                                            {documentToReactComponents(slAoRtxt8.json, options)}
                                        </p>
                                    </div>
                                </div>
                                <div className="row align-items-center pt-2">
                                    <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                        <img
                                            src={require("../images/Icons/product-occupancy/warnings.svg")}
                                            style={{ width: "100%" }}
                                            alt="image"
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                        <p className="p-regular4 text-darkgray pt-4">
                                            {documentToReactComponents(slAoRtxt9.json, options)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pt-5">

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*section 3*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row">

                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {slS3Hd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS3Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*section 4*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row">
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS4Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*section 5*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row">
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS5Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*section 6*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row pb-5">
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS6Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>
                    </div>
                    {responsive.webContainer === true ? (

                        <div className="row text-white pt-5">
                            <div className="col-lg-4 pl-0 pr-5 pb-5">
                                <img
                                    src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                    style={{ width: "20%" }}
                                    alt="image"
                                />
                                <p className="p-regular4 text-darkgray pt-4">
                                    {documentToReactComponents(slAoRtxt10.json, options)}
                                </p>
                            </div>
                            <div className="col-lg-4 pl-0 pr-5 pb-5">
                                <img
                                    src={require("../images/Icons/product-occupancy/warnings.svg")}
                                    style={{ width: "20%" }}
                                    alt="image"
                                />
                                <p className="p-regular4 text-darkgray pt-4">
                                    {documentToReactComponents(slAoRtxt11.json, options)}
                                </p>
                            </div>
                            <div className="col-lg-4 pl-0 pr-5 pb-5">
                                <img
                                    src={require("../images/Icons/product-occupancy/warnings.svg")}
                                    style={{ width: "20%" }}
                                    alt="image"
                                />
                                <p className="p-regular4 text-darkgray pt-4">
                                    {documentToReactComponents(slAoRtxt12.json, options)}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="row align-items-center pt-5">
                                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                    <img
                                        src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                                        style={{ width: "100%" }}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                    <p className="p-regular4 text-darkgray pt-4">
                                        {documentToReactComponents(slAoRtxt10.json, options)}
                                    </p>
                                </div>
                            </div>
                            <div className="row align-items-center pt-2">
                                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                    <img
                                        src={require("../images/Icons/product-occupancy/warnings.svg")}
                                        style={{ width: "100%" }}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                    <p className="p-regular4 text-darkgray pt-4">
                                        {documentToReactComponents(slAoRtxt11.json, options)}
                                    </p>
                                </div>
                            </div>
                            <div className="row align-items-center pt-2">
                                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                                    <img
                                        src={require("../images/Icons/product-occupancy/warnings.svg")}
                                        style={{ width: "100%" }}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                                    <p className="p-regular4 text-darkgray pt-4">
                                        {documentToReactComponents(slAoRtxt12.json, options)}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>

            {/*Footer Section A*/}
            <section className="bg-white">
                <div className="sectionFit-container5">
                    <div className="coloum">
                        <div className="mb-4 px-4 pt-4">
                            <div className="text-center">
                                <h1 className="h1-mediumSmall h1-darkblue pt-4">
                                    {slS7Hd}
                                </h1>
                            </div>
                            <div className="text-center">
                                <h1 className="h2-large h1-darkblue pt-4">
                                    {documentToReactComponents(slS7Rtxt.json, options)}
                                </h1>
                            </div>
                            <div className="text-center">
                                <h2 className="h2-large h1-darkblue pt-4">
                                    {slS8Hd}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Footer section B*/}
            <section className="bg-darkblue">
                <div className="sectionFit-container">
                    <div className="coloum">
                        <div className="mb-4 px-4 pt-4">
                            <div className="text-center">
                                <h1 className="h1-mediumSmall text-white pt-4">
                                    {slFHd}
                                </h1>
                            </div>
                            <div className="text-center">
                                <h2 className="h2-large h2-lightgray pt-4">
                                    {slFTxt}
                                </h2>
                            </div>
                            <div class="h-100 text-center pt-4">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--outlineWhite'
                                    buttonSize='btn--large'
                                    href={config.navigationURL_contactUs}
                                >
                                    {slFBtn}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZSolutions(
        filter: {
        node_locale: { eq: $locale },
        id: { in: ["085f0a94-3cfa-5943-b2dd-1ccb7ccb999e", "dd2bec84-df76-5069-ab88-59cac960fd44"] }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                slAoRtxt {
                    json
                }
                slAoRtxt2 {
                    json
                }
                slAoRtxt3 {
                    json
                }
                slAoRtxt4 {
                    json
                }
                slAoRtxt5 {
                    json
                }
                slAoRtxt6 {
                    json
                }
                slAoRtxt7 {
                    json
                }
                slAoRtxt8 {
                    json
                }
                slAoRtxt9 {
                    json
                }
                slAoRtxt10 {
                    json
                }
                slAoRtxt11 {
                    json
                }
                slAoRtxt12 {
                    json
                }
                slFHd
                slFTxt
                slFBtn
                slHHd
                slHHd2
                slHTxt {
                    slHTxt
                }
                slS1Hd
                slS2Hd
                slS3Hd
                slS3Rtxt {
                    json
                }
                slS4Rtxt {
                    json
                }
                slS5Rtxt {
                    json
                }
                slS6Rtxt {
                    json
                }
                slS7Hd
                slS7Rtxt {
                    json
                }
                slS8Hd
                slHImage {
                    file {
                        url
                        fileName
                    }
                    description
                    title
                }

            }
        }
    }
}
`
