import React from 'react'

function Step({ step, index, toggleStep }) {
    return (
        <div
            className={"step " + (step.open ? 'open' : '')}
            key={index}
            onClick={() => toggleStep(index)}
        >
            <div className="step-heading">
                {step.heading}
            </div>
            <div className="step-text">
                {step.text}
            </div>
        </div>
    )
}

export default Step